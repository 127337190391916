<template>
  <div class="register-page">
    <n-loader :loading="$var('load') || $var('load2')" />
    <c-go-back route="login" />
    <div class="register-wrapper">
      <div class="logo-place">
        <div class="logo">
          <n-icon icon="logo" />
        </div>
      </div>
      <n-form v-if="step === 0" class="form" @submit="recover">
        <div class="inputs">
          <n-input name="phoneinput" placeholder="Телефон" :danger="$var('m1')" class="auth-input" v-bind="$form.input('phone')"></n-input>
        </div>
        <div class="auth-buttons">
          <n-button class="auth-button" type="submit">Получить код</n-button>
        </div>
      </n-form>
      <n-form v-else-if="step === 1" class="form" @submit="sendCode">
        <div class="inputs">
          <div class="title">Введите полученный код</div>
          <c-code :message="$var('code')" :code.sync="code"></c-code>
        </div>
        <div class="auth-buttons">
          <n-button class="auth-button" type="submit" :disabled="isDisabled">Отправить</n-button>
        </div>
      </n-form>
      <n-form v-else class="form" @submit="recoverDone">
        <div class="inputs">
          <div class="title">Придумайте новый пароль</div>
          <n-input placeholder="Пароль" type="password" :danger="$var('m1')" class="auth-input" v-model="password"></n-input>
          <n-input placeholder="Подтвердите пароль" type="password" :danger="$var('m2')" class="auth-input" v-model="password2"></n-input>
        </div>
        <div class="auth-buttons">
          <n-button class="auth-button" type="submit">Сменить пароль</n-button>
        </div>
      </n-form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageRegister',
  data() {
    return {
      code: '',
      step: 0,
      password: null,
      password2: null,
      isDisabled: true,
    }
  },
  watch: {
    code() {
      if (this.code.length === 4) {
        this.isDisabled = false
      }
    },
    step() {
      this.initMessages()
    },
  },
  mounted() {
    this.accessInput()
  },
  created() {
    this.$app.auth.needAuth(false)
    this.$form.init({
      phone: '+7',
    })
    this.$form.rules({
      phone: [ 'required', ],
    })
    this.$form.masks({
      phone: [ 'phone', ],
    })
  },
  methods: {
    accessInput() {
      const inputElement = document.getElementById('phoneinput')
      inputElement.maxLength = 18
    },
    initMessages() {
      this.$var('m1', '')
      this.$var('m2', '')
      this.$var('code', '')
    },
    recoverDone() {
      if (this.password && this.password2) {
        if (this.password !== this.password2) {
          this.$var('m1', 'Пароли должны совпадать')
          this.$var('m2', 'Пароли должны совпадать')
        } else {
          const data = this.$form.get()
          data.code = this.code
          data.password = this.password
          data.password2 = this.password2
          $api.auth.recover(data).then((response) => {
            const loginData = response.data.content
            $app.auth.login(loginData.user, loginData.token, loginData.grants)
          }).catch((error) => {
            const messages = error.response.data
            this.$var('m1', $n.getMessage(messages, 'password') || $n.getMessage(messages))
            this.$var('m2', $n.getMessage(messages, 'password2')|| $n.getMessage(messages))
          }).finally(() => {
            this.$var('load', false)
          })
        }
      } else {
        if (!this.password) {
          this.$var('m1', 'Поле обязательно для заполнения')
        }
        if (!this.password2) {
          this.$var('m2', 'Поле обязательно для заполнения')
        }
      }
    },
    sendCode() {
      this.$var('load', true)
      const data = {
        phone: this.$form.get('phone'),
        code: this.code,
      }
      $api.auth.checkCode(data).then(() => {
        this.step = 2
      }).catch((error) => {
        const messages = error.response.data
        this.$var('code', $n.getMessage(messages))
      }).finally(() => {
        this.$var('load', false)
      })
    },
    recover() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.auth.recoverCheck(this.$form.get()).then(() => {
          this.step = 1
        }).catch((error) => {
          const messages = error.response.data
          this.$var('m1', $n.getMessage(messages, 'phone'))
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        if (!this.$form.get('phone')) {
          this.$var('m1', 'Поле обязательно длня заполнения')
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.register-page{
  .register-wrapper {
    min-height: 100vh;
    padding: 100px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .bottom-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo-place {
    margin-bottom: 60px;
  }

  .form {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .forget {
    color: var(--secondary);
    text-align: center;
    text-decoration: underline;
  }

  .auth-buttons {
    margin-top: 160px;
    display: flex;
    align-items: center;
    flex-direction: column;

    ::v-deep .n-button {
      margin-bottom: 20px;
      background-color: var(--primary);
      width: 85vw;
      height: 40px;
      border-radius: 32px;

      .content {
        color: #FFFFFF;
        font-size: 1em;
      }
    }
  }
  .inputs {
    .title {
      margin-bottom: 30px;
      text-align: center;
    }
  }
  .auth-input {
    margin-bottom: 20px;
  }
}
</style>
